

























































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { GET_WAITING_FOR_KODE_DETAILS, SET_EVALUATION_DETAILS_SENT } from '@/store/kode/actions'
import { Action } from 'vuex-class'
import { KodeEvaluationDetailsSentType, KodeEvaluationTalentData } from '@/store/kode/types'
import { hasRight } from '@/utils/rights'
import Rights from '@/common/rights'
import ResultsPage from '@/components/search-results/ResultsPage.vue'
import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
import { SortingInfo, FilteringType } from '@/store/commonFormInput/types'
import SearchResult from '@/components/search-results/SearchResult.vue'
import { SimpleServerResponse } from '@/store/types'
import { now } from 'moment'

@Component({
  components: { SearchResult, LoadingSpinner, ResultsPage }
})
export default class KodeEvaluationDetailsSent extends Vue {
  @Action(GET_WAITING_FOR_KODE_DETAILS)
  public getWaitingForKodeDetail: () => Promise<Array<KodeEvaluationTalentData>>

  @Action(SET_EVALUATION_DETAILS_SENT)
  public setEvaluationDetailsSent: (params: KodeEvaluationDetailsSentType) => Promise<SimpleServerResponse>

  errorMessage = ''
  showErrorMessage = false

  loading = false

  talentData: KodeEvaluationTalentData[] = []

  mounted (): void {
    if (hasRight(Rights.KODE_MARK_LOGIN_DETAILS_SENT)) this.loadWaitingForKodeDetails()
  }

  getFullName (talent: KodeEvaluationTalentData): string {
    return `${talent.salutation && talent.salutation !== 'EMPTY_DUMMY' ? this.$t(`personal-information.SALUTATIONS.${talent.salutation}`) : ''} ${talent.firstname} ${talent.lastname}`
  }

  loadWaitingForKodeDetails (): void {
    this.loading = true
    this.getWaitingForKodeDetail().then(data => {
      this.talentData = data
    }).catch(error => {
      this.errorMessage = error
      this.showErrorMessage = true
    }).finally(() => {
      this.loading = false
    })
  }

  markEvaluationDetailsSent (id: string): void {
    const params: KodeEvaluationDetailsSentType = { profileStudentId: id, sent: true }
    this.setEvaluationDetailsSent(params).then(data => {
      this.$root.$emit('alert', this.$i18n.t('kode-evaluation.marked-as-sent').toString(), '')
      this.talentData[this.talentData.findIndex(el => el.profileId === id)].kodeDetailsSent = new Date(now())
    }).catch(error => {
      this.errorMessage = error
      this.showErrorMessage = true
    })
  }

  get sortable (): SortingInfo[] {
    return [
      { key: 'firstname', label: this.$i18n.t('personal-information.firstName').toString() },
      { key: 'lastname', label: this.$i18n.t('personal-information.lastName').toString() },
      { key: 'email', label: this.$i18n.t('personal-information.email').toString() }
    ]
  }

  get filterKeys (): FilteringType[] {
    return this.sortable
  }

  get viewTalentProfileRight (): boolean {
    return hasRight(Rights.STUDENT_VIEW)
  }
}
